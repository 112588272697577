import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Select, Typography, Button, Chip, MenuItem, FormControl } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import language from '../layout/language';
import classNames from 'classnames';
import Utils from '../api/api';
import theme, { styles } from '../layout/theme';

class PermissionDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      permission: {
        group:'',
        permissions: [],
      },
      error: {
        status:false,
      },
      failed:false,
      message:'',
      isUpdate: false,
      usergroup: [],
      pages: [{
        title: 'Hình',
        value: 'album'
      },{
        title:'Nhóm khóa học offline',
        value: 'course-offline-category'
      },{
        title: 'Nhóm khóa học online',
        value: 'course-online-category'
      },{
        title: 'Khóa học offline',
        value: 'course-offline'
      },{
        title: 'Khóa học online',
        value: 'course-online'
      },{
        title: 'Lịch khai giảng',
        value: 'calendar'
      },{
        title: 'Giảng viên',
        value: 'lecturers'
      },{
        title: 'Tài liệu',
        value: 'document'
      },{
        title: 'Dịch vụ',
        value: 'service'
      },{
        title: 'Lịch đặt dịch vụ',
        value: 'booking'
      },{
        title: 'Hóa đơn',
        value: 'bill'
      },{
        title: 'Tài khoản/ Khách hàng/ Học viên',
        value: 'account'
      },{
        title: 'Project cuối khóa',
        value: 'projects'
      },{
        title: 'Nội dung chia sẻ',
        value: 'shareblocks'
      },{
        title: 'Trang nội dung',
        value: 'pages'
      },{
        title: 'Biểu ngữ',
        value: 'banner'
      },{
        title: 'Thanh điều hướng',
        value: 'menu'
      },{
        title: 'Nhận xét của khách hàng',
        value: 'testimonial'
      },{
        title: 'Nhóm tin tức',
        value: 'newscat'
      },{
        title: 'Tin tức',
        value: 'news'
      },{
        title: 'Blog category',
        value: 'blogcat'
      },{
        title: 'Blog',
        value: 'blog'
      },{
        title: 'Công ty',
        value: 'company'
      },{
        title: 'Ngành nghề',
        value: 'career'
      },{
        title: 'Tin tuyển dụng',
        value: 'hiring'
      },{
        title: 'Ứng viên',
        value: 'candidate'
      },{
        title: 'FAQ group',
        value: 'faqgroup'
      },{
        title: 'FAQ',
        value: 'faq'
      },{
        title: 'Tài khoản',
        value: 'user'
      },{
        title: 'Tài khoản subscribers',
        value: 'subscribers'
      },{
        title: 'Chiến dịch email',
        value: 'campaign'
      },{
        title: 'Quản lý tập tin',
        value: 'media'
      },{
        title: 'Cấu hình',
        value: 'setting'
      }]
    };
  }

  componentDidMount(){
    //console.log(this.props.action)
    let $this = this;
    let params = $this.context.router.route.match.params;

    Utils.getListData('usergroup','all',100,'0,0',null,1,function(data){
      $this.setState({usergroup:data.results});
    });

    if(params.id!==undefined){
      $this.setState({isUpdate:true},function(){
        Utils.getSingleData('permission',params.id,function(res){
          //console.log(res);
          if(res.status=='success'&&res.results!==undefined){
            let data = {};
            data.group = res.results.group;
            data._id = res.results._id;
            data.permissions = res.results.permissions;
            $this.setState({permission:data},function(){
              //console.log($this.state);
            });
          }
        });
      });
    }
  }

  handleValidate(e){
    let $this = this,
    permission = $this.state.permission,
    obj = $this.state.error;

    permission[e.target.id] = e.target.value;


    if (e.target.value.trim() === "" || e.target.value === null) {
      obj.status = true;
    } else {
      obj.status = false;
    }


    $this.setState({ permission: permission, error: obj });
  }
  handleFieldChange(e,field) {
    let $this = this,
        permission = this.state.permission;

    if(field===undefined) {
      permission[e.target.id] = e.target.value;
    } else {
      permission[field] = e.target.value;
    }
    $this.setState({ permission: permission });
  }
  _save() {
    let $this = this;
    let data = $this.state.permission;
    if ($this.state.isUpdate) {
      Utils._update("permission", data, function(res) {
        //console.log(res);
        if (res.status === "success") {
          $this.context.router.history.push("/permission/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message)
          });
        }
      });
    } else {
      Utils._add("permission", data, function(res) {
        if (res.status === "success") {
          $this.context.router.history.push("/permission/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message)
          });
        }
      });
    }
  }

  handleSave() {
    let params = this.context.router.route.match.params;
    let $this = this;

    if (params.id !== undefined && !$this.state.isUpdate) {
      $this.setState({isUpdate: true});
    }
    
    if ($this.state.error.status) {
      return;
    } else if (!$this.state.error.status) $this._save();
  }
  handleCancel(){
    this.context.router.history.push('/permissions/');
  }

  render(){
    const classes = this.props.classes;
    const permission = this.state.permission;
    return(
      <div className="page-detail">
        <form className={classes.form} noValidate>
          <Typography type="body2" className={classNames(classes.error, !this.state.error.status && classes.hide)}>Vui lòng nhập tất cả các field (*)</Typography>
          <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
          <FormGroup className={classes.formRow}>
            <Typography className={classes.label}>Nhóm</Typography>
            <Select
              native
              className={classes.selectMultiLevel}
              id="group"
              value={permission.status}
              onChange={event => this.handleFieldChange(event)}
            >
              <option value="" key={0}>
                ...
              </option>
              {this.state.usergroup.map((item, index) => {
                return item.name.toLowerCase()!=='administrator' && (
                  <option key={index + 1} value={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
          </FormGroup>
          <FormGroup className={classes.formRow}>
            <Typography className={classes.label}>Các page được truy cập</Typography>
            <FormControl className={classes.chipRow}>
              {permission.permissions !== undefined &&
                permission.permissions !== null &&
                permission.permissions.length > 0 &&
                permission.permissions.map((item, index) => (
                  this.state.pages.map((y,z)=>{
                    if(item===y.value) {
                      return (
                        <Chip
                          label={y.title}
                          key={z}
                          onDelete={() => this.handleDeletePage(item, index)}
                          className={classes.chip}
                        />
                      )
                    }
                  })
                ))}
            </FormControl>
            <Select
              className={classes.selectMultiLevel}
              id="permissions"
              value={permission.permissions}
              onChange={event => this.handleFieldChange(event,'permissions')}
              multiple
            >
              <MenuItem value="" key={0}>
                ...
              </MenuItem>
              {this.state.pages.map((item, index) => {
                return (
                  <MenuItem key={index + 1} value={item.value}>
                    {item.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormGroup>
          <FormGroup row className={classes.formRow}>
            <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
            <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event)}>{language.VN.btnSave}</Button>
          </FormGroup>
        </form>
      </div>
    )
  }
}

PermissionDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
PermissionDetail.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(PermissionDetail);
