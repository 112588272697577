import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Toolbar} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import ReactTable from './shares/react_table';
import theme,{styles} from '../layout/theme';
import Utils from '../api/api';

export class PermissionList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false,
      usergroup: [],
    };
  }
  componentDidMount() {
    const $this = this;
    Utils.getListData('usergroup','all',100,'0,0',null,1,function(data){
      $this.setState({usergroup:data.results});
    });
  }
  handleAdd(){
    this.context.router.history.push('/permission/add');
  }
  render(){
    const $this = this;
    const classes = $this.props.classes;
    var source = {
      thead: [{title:'Nhóm',value:'group'},{title:'Quyền',value:'permissions'}, ],
      tbody: function (n) {
        return ({
          _id: n._id,
          group: $this.state.usergroup.map(x=>{return x._id === n.group && (<div key={x._id}>{x.name}</div>)}),
          permissions: n.permissions.map(x=>{return (<div key={x}>{x}</div>)})
        });
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography variant="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục phân quyền</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar + ' flex-right'}>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiList='permissions' apiSingle='permission' apiTable='permission' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick}>
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

PermissionList.propTypes = {
  classes: PropTypes.object.isRequired,
};
PermissionList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(PermissionList);
