import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PermissionList from './permission_list';
import PermissionDetail from './permission_detail';

class Permission extends Component {
  componentDidMount(){
  }
  UNSAFE_UNSAFE_componentWillReceiveProps(nextProps){
    //console.log(nextProps);
    if(nextProps.group!=='Administrator'&&nextProps.group!==''){
      this.context.router.history.push('/');
    }
  }
  render() {
    //console.log(this.context);
    var params = this.context.router.route.match.params;
    var view;
    if(params.action===undefined){
      view = <PermissionList/>;
    }else{
      if(params.id===undefined){
        view = <PermissionDetail/>;
      }else{
        view = <PermissionDetail action={params.action}/>;
      }
    }
    return (
      <div className="page-wrapper">{view}</div>
    );
  }
}

Permission.contextTypes = {
  router: PropTypes.object
};

export default Permission;
